import { useRef }                                       from "react";
import { Swiper, SwiperSlide }                          from "swiper/react";
import { Autoplay, Pagination, Navigation }             from 'swiper/modules';
import { useNavigate, useLocation   }                   from "react-router-dom";

import { _lang_base }                                   from "../../Languages/languages";

const importAll                                 = (context) => context.keys().map(context);
const imagesGalery                              = (_lang_base.lang_base === "pt_BR") ? 
  importAll(require.context(`../../assets/img/store/slide/pt_BR/`, false, /\.(jpg)$/)) :
  importAll(require.context(`../../assets/img/store/slide/en/`, false, /\.(jpg)$/))

function SlideNewProducts() {
  const history                                       = useNavigate();
  const progressCircle                                = useRef(null);
  const progressContent                               = useRef(null);
  const onAutoplayTimeLeft                            = (s, time, progress) => {
    progressCircle.current.style.setProperty('--progress', 1 - progress);
    progressContent.current.textContent = `${Math.ceil(time / 1000)}s`;
  };

  return(
    <Swiper 
      spaceBetween={30}
      centeredSlides={true}
      autoplay={{delay: 7000, disableOnInteraction: false}}
      pagination={{clickable: true}}
      navigation={true}
      rewind={true}
      modules={[Autoplay, Pagination, Navigation]}
      onAutoplayTimeLeft={onAutoplayTimeLeft}
      className="mySwiper"
    >
      {imagesGalery.map((item, index) =>
      <SwiperSlide key={index} onClick={() => history(`/store`)}>
        <img src={item} alt={_lang_base.web_site_name} />
      </SwiperSlide>
      )}
      <div className="autoplay-progress" slot="container-end">
        <svg viewBox="0 0 48 48" ref={progressCircle}>
          <circle cx="24" cy="24" r="20"></circle>
        </svg>
        <span ref={progressContent}></span>
      </div>
    </Swiper>
  )
}

export default SlideNewProducts;
