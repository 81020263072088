import { useState, useEffect }                        from "react";

import { AUTH }                                       from "../Api/Conexao";
import Context                                        from "./Context";
import { gen_products }                               from "../Utils/StoreFunction";
import { _get_storage, storage_vars, _save_storage,
  _remove_storage }                                   from "../Storage/Storage";

import SocialLinksBase                                from "../Json/SocialLinks";
import UserNotificationsReq                           from "../Json/UserNotifications";

import { _lang_base }                                 from "../Languages/languages";

if(_get_storage(storage_vars.cookies) !== 1) {
  _remove_storage("notificacoes");
  _remove_storage("idioma");
  _remove_storage("carrinho");
  _remove_storage(storage_vars.language);
  _remove_storage(storage_vars.cart);
  _remove_storage(storage_vars.checkout);
  _remove_storage(storage_vars.popups);
  _remove_storage(storage_vars.user_auth);
  _remove_storage(storage_vars.notificacoes);
  _remove_storage(storage_vars.user_config);
  _save_storage(storage_vars.cookies, 1);
  window.location.href = "/";
}

const user_config_base = {
  header_vertical_status: true,
  category_product_ui: "0",
  products_favorites: []
}

/* BASE VARS */
let ProductsReq               = { free: [], vip: [] };
let FaqVideosReq              = { support: [] };
let RechargeLinksReq          = [];
let SocialValuesReq           = { total_users: "0", total_years: "0", total_mods: "0" };
let PopupsReq                 = _get_storage(storage_vars.popups) ? _get_storage(storage_vars.popups) : { tiktok_live: { date: "2024-04-24T21:28:41.489Z", show: true }};
let UserInfoReq               = _get_storage(storage_vars.user_auth);
let ProductsCartArr           = _get_storage(storage_vars.cart) ? _get_storage(storage_vars.cart) : [];
let CheckoutReq               = _get_storage(storage_vars.checkout) ? _get_storage(storage_vars.checkout) : null;
let CouponsReq                = _get_storage(storage_vars.coupons) ? _get_storage(storage_vars.coupons) : undefined;
let UserConfigReq             = _get_storage(storage_vars.user_config) ? _get_storage(storage_vars.user_config) : user_config_base;
let SocialLinksReq            = SocialLinksBase;

let UserNotificationsArr      = [];
UserNotificationsArr          = UserNotificationsReq;

async function GenerateValues() {
  try {
    const products                    = await AUTH.get(`/json/update_products.json?timestamp=${new Date().getTime()}`).then((req) => req.data);
    const update_faq_links            = await AUTH.get(`/json/update_faq_links.json?timestamp=${new Date().getTime()}`).then((req) => req.data);
    const update_recharge_links       = await AUTH.get(`/json/update_recharge_links.json?timestamp=${new Date().getTime()}`).then((req) => req.data);
    const update_social_links         = await AUTH.get(`/json/update_social_links.json?timestamp=${new Date().getTime()}`).then((req) => req.data);
    const update_social_values        = await AUTH.get(`/json/update_social_values.json?timestamp=${new Date().getTime()}`).then((req) => req.data);
    update_social_values.total_mods   = [...products.free, ...products.vip].length;
    return {
      products,
      update_faq_links,
      update_recharge_links,
      update_social_values,
      update_social_links,
    }
  } catch (error) {
    return {
      products: ProductsReq,
      update_faq_links: FaqVideosReq,
      update_recharge_links: RechargeLinksReq,
      update_social_values: SocialValuesReq,
      update_social_links: SocialLinksReq,
    }
  }
}

function Provider({ children }) {
  const [user_info, set_user_info]                    = useState(UserInfoReq);
  const [products_cart, set_products_car]             = useState(ProductsCartArr);
  const [user_checkout, set_user_checkout]            = useState(CheckoutReq);
  const [user_notifications, set_user_notifications]  = useState(UserNotificationsArr);
  const [popups_info, set_popups_info]                = useState(PopupsReq);
  const [coupons_value, set_coupons_value]            = useState(CouponsReq);
  const [open_chat, set_open_chat]                    = useState(false);

  const [Products, SetProducts]                       = useState(ProductsReq);
  const [FaqVideos, SetFaqVideos]                     = useState(FaqVideosReq);
  const [RechargeLinks, SetRechargeLinks]             = useState(RechargeLinksReq);
  const [SocialValues, SetSocialValues]               = useState(SocialValuesReq);
  const [SocialLinks, SetSocialLinks]                 = useState(SocialLinksReq);
  const [user_config, set_user_config]                = useState(UserConfigReq);

  useEffect(() => {
    (async () => {
      const generate_values = await GenerateValues();
      SetProducts(gen_products(generate_values.products, _lang_base));
      SetFaqVideos(generate_values.update_faq_links);
      SetRechargeLinks(generate_values.update_recharge_links);
      SetSocialLinks(generate_values.update_social_links);
      SetSocialValues(generate_values.update_social_values);
    })();
  }, []);

  const UserInfoBase                                  = { 
    Value: user_info, 
    Update: (item) => {
      set_user_info({ ...user_info, ...item });
      _save_storage(storage_vars.user_auth, { ...user_info, ...item });
    }
  };

  const ProductsCart                                  = { 
    Value: products_cart, 
    Update: (item) => {
      set_products_car(item);
      _save_storage(storage_vars.cart, item);
    }
  };

  const UserCheckout                                  = { 
    Value: user_checkout, 
    Update: (item) => {
      set_user_checkout(item);
      _save_storage(storage_vars.checkout, item);
    }
  };

  const UserNotifications                             = { 
    Value: user_notifications, 
    Update: (item) => {
      set_user_notifications(item);
    }
  };

  const PopUps                                        = {
    Value: popups_info, 
    Update: (item) => {
      set_popups_info({ ...popups_info, ...item });
      _save_storage(storage_vars.popups, { ...popups_info, ...item });
    }
  }

  const Coupons                                        = {
    Value: coupons_value, 
    Update: (item) => {
      set_coupons_value(item);
      _save_storage(storage_vars.coupons, item);
    }
  }

  const Chat                                        = {
    Value: open_chat, 
    Update: (item) => {
      set_open_chat(item);
    }
  }

  const UserConfigBase                                   = {
    Value: user_config, 
    Update: (item) => {
      set_user_config({ ...user_config, ...item });
      _save_storage(storage_vars.user_config, { ...user_config, ...item });
    }
  }

  return(
    <Context.Provider 
      value={{
        UserInfoBase,         
        ProductsCart,
        UserNotifications,
        Products,
        FaqVideos,
        RechargeLinks, 
        SocialValues,
        SocialLinks,
        PopUps,
        UserCheckout,
        Coupons,
        Chat,
        UserConfigBase
      }}
    > 
      {children}
    </Context.Provider>
  )
}

export default Provider;
